import BuildForSDGHero from '../components/BuildForSDGHero';

const InventorsBuildForSDG = () => {
	return (
		<div>
			<BuildForSDGHero />
		</div>
	);
};

export default InventorsBuildForSDG;
