import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

import Footer from './components/Footer';
import InventorsBuildForSDG from './pages/InventorsBuildForSDG';

const App = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route
					path="/inventorsbuildforsdg"
					element={<InventorsBuildForSDG />}
				/>
			</Routes>
			<Footer />
		</>
	);
};

export default App;
