import './index.scss';
import { NavLink } from 'react-router-dom';

const NavbarTransparent = () => {
	return (
		<nav className="navbar container">
			<div className="navbar2">
				<NavLink to="/" className="nav-brand">
					<img
						src={require('../../assets/img/logo.png')}
						alt="Inventors Logo"
					/>
				</NavLink>
				<div className="mobile-btn">
					<NavLink to="/inventorsbuildforsdg" className="btn">
						Inventors Build For SDG
					</NavLink>
				</div>
			</div>
			<ul className="d-sm-none nav-items">
				<li>
					<a href="#about-us">About Us</a>
				</li>
				<li>
					<a href="#events">Events</a>
				</li>
				<li>
					<a href="#impacts">Our Impacts</a>
				</li>
				<li>
					<NavLink to="/inventorsbuildforsdg">Inventors Build For SDG</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default NavbarTransparent;
