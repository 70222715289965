import './index.scss';
import NavbarTransparent from '../NavbarTransparent';

import Build4SDG from './../../assets/img/build4sdg.png';

const BuildForSDGHero = () => {
	return (
		<section className="hero-container">
			<NavbarTransparent />
			<div className="sec_container">
				<div className="heading">
					<h1>Inventors BuildforSDG Challenge</h1>
				</div>
				<div className="card">
					<p>
						The Inventors developers community has been actively involved in
						grooming developers to solve and bring solutions to problems we find
						around us. To further solve more problems through technology, we
						would be introducing our newest developer program which is the
						#BuildforSDG Challenge.
					</p>
					<h2>About the Program </h2>
					<p>
						#BuildforSDG Challenge is a three (3) months program committed to
						empowering you to build real-world, locally, and relevant solutions
						focused on the Sustainable Development Goals(SDGs). It's an
						opportunity to gain the skills you need to advance your career in
						Tech. Your team will work on a selected SDG problem to innovate a
						solution. The top two (2) teams from the challenge will be rewarded.
					</p>
					<h2>The Goal</h2>
					<p>
						The main goal is to solve a community problem using Technology and
						also fast track the path to competence, market-readiness & technical
						leadership of developers to scale by developing tech-driven
						solutions to advance the SDGs.
					</p>
					<h2>The Strategy</h2>
					<p>
						You will be added to a team of 4-5 members and then you choose a
						project to work on that identifies a relevant problem relating to
						SDG and build an innovative solution with solid software engineering
						standards.
					</p>
					<h2>Getting Started</h2>
					<p>
						To participate in the Inventors #BuildforSDG Challenge, you must
						have any of the required skills(Frontend, Backend, Ui/UX, Graphics
						Design, Data Science) which we will verify, if verified you get
						registered as an inventor and you ready for the program. Emails on
						steps to complete the process will be sent after you have completed
						the form in the bottom.
					</p>
					<img src={Build4SDG} alt="" />
					<h2>How To Participate</h2>
					<p>
						After you have been added to your team of four to five members (at
						least one UI/UX designer, one Frontend developer, two Backend
						developers, Data scientist, One graphics designer )
					</p>
					<ul>
						<li>Select an SDG problem you wish to solve </li>
						<li>Join the Inventors slack channel </li>
						<li>Search for your team on our slack channel</li>
						<li>
							Your Team Lead will Create a GitHub Repository on the inventors
							community’s org where your team can collaborate on the chosen
							project.
						</li>
					</ul>
					<div className="btns">
						<a
							href="https://forms.gle/kw12hGQjVhF88jHi6"
							className="btn btn-primary"
						>
							Register
						</a>
						<a href="https://discord.gg/DhsUsbqk" className="btn btn-secondary">
							Discord Server
						</a>
						<a
							href="https://www.un.org/development/desa/disabilities/envision2030.html"
							className="btn btn-primary"
						>
							SDG's
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BuildForSDGHero;
